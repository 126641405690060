<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">예약콜조회</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div class="">
          <div class="form-inp sm">
            <v-select
                :items="this.$store.getters['userStore/GE_USER_COMPANY']"
                item-text="CD_NM"
                item-value="CD"
                outlined
                hide-details
                label="회사구분"
                placeholder="선택하세요"
                v-model="ASP_NEWCUST_KEY"
                @change="changeKey($event)"
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              content-class="calendar-modal"
              color="#FFF"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="dateRangeText"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  outlined
                  hide-details
                  class="form-inp icon-calendar ml-2"
                  append-icon="svg-calendar"
                  label="예약일자"
              ></v-text-field>
            </template>
            <div class="datepicker-range">
              <v-date-picker
                  v-model="dates[0]"
                  no-title
                  dark
                  color="#F7AFAE"
                  @change="startDate"
                  :first-day-of-week="1"
                  locale="ko-KR"
                  :day-format="mixin_getDate"
              ></v-date-picker>
              <v-date-picker
                  v-model="dates[1]"
                  dark
                  color="#F7AFAE"
                  no-title
                  show-current="false"
                  :min="dates[0]"
                  @change="endDate"
                  :first-day-of-week="1"
                  locale="ko-KR"
                  :day-format="mixin_getDate"
              ></v-date-picker>
            </div>
            <div class="text-center pt-3 pb-3">
              <v-btn
                  outlined
                  class="btn-default"
                  @click="menu1 = false"
              >확인</v-btn>
            </div>
          </v-menu>
          <div class="form-inp ml-2">
            <v-select
                :items="this.mixin_common_code_get(this.commonCode, 'CRS022', '전체')"
                outlined
                hide-details
                label="처리상태"
                placeholder="선택하세요"
                v-model="FISH_YN"
            ></v-select>
          </div>
          <v-text-field
              class="form-inp sm ml-2"
              name="name" label="사용자 ID" outlined hide-details
              v-model="USER_ID">
          </v-text-field>
          <v-text-field
              class="form-inp sm ml-2"
              name="name" label="사용자명" outlined hide-details
              v-model="USER_NM">
          </v-text-field>
          <v-text-field
              class="form-inp sm ml-2"
              name="name" label="닉네임" outlined hide-details
              v-model="USER_NICK">
          </v-text-field>
          <!-- 조회 버튼 -->
          <v-btn 
            v-if="this.mixin_set_btn(this.$options.name, 'btnPopupUser')"
            dark small
            class="btn-main-search pa-0 ml-2" min-width="32px" height="32px" plain
            @click="showDialog('MP02')">
            <v-icon small class="svg-main-searh white"></v-icon>
          </v-btn>
          <!-- 새로고침 버튼 -->
          <v-btn 
            v-if="this.mixin_set_btn(this.$options.name, 'btnInitUser')"
            dark small
            class="btn-main-refresh pa-0 ml-2" min-width="32px" height="32px"
            @click="resetBtn"
            plain
            >
            <v-icon small class="svg-main-refresh"></v-icon>
          </v-btn>
          <!-- 모달 : 조회 -->
          <v-dialog max-width="1400" max-height="1200" persistent v-model="dialogMP02" v-if="dialogMP02" content-class="square-modal">
            <dialog-MP02
              headerTitle="고객정보"
              :userInfo="userInfo"
              @selectUser="[hideDialog('MP02'), setUser()]"
              @hide="hideDialog('MP02')"
              >
            </dialog-MP02>
          </v-dialog>
        </div>
        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined  class="btn-etc2" @click="getListOrNot" @keyup.enter="getListOrNot">조회</v-btn>
        </div>
      </div>
    </div>
    <!-- 예약콜리스트 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">예약콜리스트
        <div class="ml-auto">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnExcelDown')" outlined class="btn-point ml-2" @click="downloadExcel('mainTable', '예약콜조회')">엑셀다운로드</v-btn>
        </div>
      </h2>
      <div class="box-ct">
        <v-data-table
            dense
            fixed-header
            height="549px"
            :headers="gridDataHeaders"
            :items="gridDataText"
            :items-per-page="30"
            item-key="index"
            :page.sync="page"
            hide-default-footer
            class="grid-default"
            show-select
            @page-count="pageCount = $event"
            id="mainTable"
            v-model="checkedList"
            @dblclick:row="dblClickData"

            :item-class="isActiveRow"
            @click:row="rowSelect"
        >
          <template v-slot:item.RESV_DATE = "{ item }">
            <div> {{ dataFormat (item.RESV_DATE) }}</div>
          </template>
          <template v-slot:item.CNSL_BEGIN_DATE = "{ item }">
            <div> {{ dataFormat (item.CNSL_BEGIN_DATE) }}</div>
          </template>
        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="totalVisible"
              next-icon="svg-paging-next"
              prev-icon="svg-paging-prev"></v-pagination>
        </div>
      </div>
    </div>
    <!--// 예약콜리스트 -->

  </div>
</template>

<script>
import api from '@/store/apiUtil.js';
import DialogMP02 from "./MP02.vue";
import {mixin} from "@/mixin/mixin.js"

export default {
  mixins:[mixin], // mixin 등록
  name: "MENU_M110501", //name은 'MENU_' + 파일명 조합
  components: {
    DialogMP02,
  },
  data() {
    return{
      dialogMP02: false,
      menu1: false,
      dates: [
        (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      ],

      commonCodeArr: ["CRS022"],
      commonCode: [],

      checkedList: [],

      userInfo: {},
      USER_ID: '',
      USER_NM: '',
      USER_NICK: '',

      gridDataHeaders: [
        {
          text: 'No',
          align: 'center',
          value: 'index',
          width: '70px',
          sortable: true,
        },
        { text: '회사명', value: 'ASP_NEWCUST_KEY', align: 'center', sortable: true, },
        { text: '예약일자', value: 'RESV_DATE',align: 'center', sortable: true, },
        { text: '예약시간', value: 'RESV_TIME', align: 'center',sortable: true, },
        { text: '고객번호', value: 'CUST_NO', align: 'center',sortable: true, },
        { text: '고객명', value: 'CUST_NM', align: 'center',sortable: true, },
        { text: '예약전화번호', value: 'RESV_TEL_NO_VIEW', align: 'center',sortable: true, },
        { text: '최근통화일자', value: 'CNSL_BEGIN_DATE', align: 'center',sortable: true },
        { text: '최근통화시간', value: 'TEL_TIME', align: 'center',sortable: true },
        { text: '처리상태', value: 'FISH_YN', align: 'center',sortable: true, },
        { text: '상담원사번', value: 'CSLT_ID', align: 'center',sortable: true, },
        { text: '상담원명', value: 'CSLT_NM', align: 'center',sortable: true, },
      ],
      gridDataText: [],
      aspDataDropItem: [],

      ASP_NEWCUST_KEY: '',
      RESV_STR_DT: '',
      RESV_END_DT: '',
      FISH_YN: '',
      CSLT_ID: '',
      CSLT_NM: '',
      CSLT_NICK: '',


      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      totalVisible: 10,

      selectedRow: null,
    }
  },
  async mounted(){
    // 헤더 초기화 및 세팅
    this.commonCode = await this.mixin_common_code_get_all(this.commonCodeArr);
  },
  methods: {

    startDate(e){
      this.dates[0] = e;
    },
    endDate(e){
      this.dates[1] = e;
    },
    showDialog(type) {
      if(this.ASP_NEWCUST_KEY == '' || this.ASP_NEWCUST_KEY == null){
        this.common_alert('회사구분을 선택해주세요.', 'error');
        return;
      }else{
        this[`dialog${type}`] = true;
      }
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    submitDialog(type) {
      this[`dialog${type}`] = false;
    },
    getListOrNot(){
      let companyKey = this.ASP_NEWCUST_KEY;

      if(companyKey){
        this.getListData();

        //체크된 데이터 초기화
        this.checkedList = [];

      }else{
        this.common_alert('회사구분을 선택해주세요.', 'error');
      }
    },
    async getListData(){
      let requestData = {
        headers: {},
        sendData: {},
      };

      requestData.headers["URL"] = "/api/phone/reservationcall/list";
      requestData.headers["SERVICE"] = 'phone.reservationcall';
      requestData.headers["METHOD"] = "list";
      requestData.headers["ASYNC"] = false;

      requestData.headers["PAGES_CNT"] = 1;
      requestData.headers["ROW_CNT"] = 100;

      //sendData 초기화
      requestData.sendData['ASP_NEWCUST_KEY'] = this.ASP_NEWCUST_KEY //회사명
      requestData.sendData['RESV_STR_DT'] = this.dates[0];           //예약시작일
      requestData.sendData['RESV_END_DT'] = this.dates[1];           //예약종료일
      requestData.sendData['FISH_YN'] =  this.FISH_YN;               //처리상태
      requestData.sendData['CSLT_ID'] =  this.USER_ID;  //사용자ID


      let response = await this.common_postCall(requestData);
      this.getListRes(response);

    },
    getListRes(res){
       let header = res.HEADER;
       this.pageCount = header.TOT_COUNT;

       let data = res.DATA;
       this.gridDataText = data;

       let idx = 1;
       for(var i in this.gridDataText){
         this.gridDataText[i]["index"] = idx++;
       }
    },
    downloadExcel(tableId, fileNm){
        //엑셀다운로드
        this.table_id = tableId;
        this.file_name = fileNm;
        this.mixin_common_exportExcel();
    },
    dataFormat(date){
      //날짜 포맷
      if(!date) return "";
      var newDate = "";

      date = date.replace(/\s/gi, "");

      if(date.length == 8){
        newDate = date.replace(/(\d{4})(\d{2})(\d{2})/, '$1/$2/$3');
        return newDate;
      }else{
        return date;
      }
    },
    setUser(){
      this.USER_ID = this.userInfo.USER_ID;
      this.USER_NM = this.userInfo.USER_NM;
      this.USER_NICK = this.userInfo.USER_NICK;
    },
    resetBtn(){
       //사용자 조회 초기화
      this.userInfo.USER_ID = '';
      this.userInfo.USER_NM = '';
      this.userInfo.USER_NICK = '';
      this.USER_ID = ''
      this.USER_NM = ''
      this.USER_NICK = ''
    },

    changeKey(event){
      this.userInfo.ASP_CUST_KEY = event;

    },
    dblClickData(event, row){
      this.common_alert('메인 화면 이동', 'error');
    },
    rowSelect(idx) {
      this.selectedRow = idx;
    },
    isActiveRow(item){
      const activeClass = item === this.selectedRow ? 'active' : '';
      return activeClass;
    },
  },
  computed: {
    dateRangeText () {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + ' ~ ' + newEndDate;
    },
  },
}
</script>

<style>

</style>
